var site = site || {};
var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.productGrid = {
    attach: function (context, settings) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      // var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);
      var $allProductCells = $('.js-product-grid-item', context);
      var toutsPlaceholder = [];

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        var arrowsDiv = $(this).parent().find('.carousel-controls');
        var dotsDiv = $(this).parent().find('.carousel-dots');
        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        if (!$(this).hasClass('slick-initialized')) {
          $(this).slick(settings);
        }
        // On before slide change
        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('product-id');
        });

        return allProductIds;
      };

      // snapshot of initial tout position before sorting
      $allProductCells.each(function (i) {
        if ($(this).find('.product-grid__tout').length > 0) {
          toutsPlaceholder.push({
            $item: $(this),
            position: i
          });
        }
      });

      var sortGrid = function (sortFn) {
        var allProductIds = collectGridProductIds($allProductCells);
        var allProductData = [];

        _.each(allProductIds, function (prodId, i) {
          allProductData.push(prodcat.data.getProduct(prodId));
        });
        // console.log(_.pluck(allProductData, "PRICE"));
        var sortedProductData = _.sortBy(allProductData, sortFn);
        // console.log(_.pluck(sortedProductData, "PRICE"));
        var sortedCells = [];

        _.each(sortedProductData, function (prod, i) {
          if (prod) {
            sortedCells.push($('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']'));
          }
        });

        // apply original tout positions after sorting products
        _.each(toutsPlaceholder, function (tout, i) {
          if (tout) {
            sortedCells.splice(tout.position, 0, tout.$item);
          }
        });

        var $grid = $('.js-product-grid');
        var $gridContent = $('.product-grid__content', $grid);

        $gridContent.empty();
        _.each(sortedCells, function (cell, i) {
          $gridContent.append($(cell));
        });

        $(document).trigger('grid.reflow');

        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.reset($grid);
          prodcat.ui.quickshop.init($grid);
        }
      };

      $(document).on('mpp_sort:selected', function (e, sortFn) {
        var $productGrid = $('.js-product-grid', context);

        if ($productGrid.length > 0) {
          sortGrid(sortFn);
          site.util.grids.equalHeightsGrids($productGrid);
        }
      });

      $grids.on('product.updateInvStatus', '.js-product-grid-item', function () {
        var $statusList = $('.js-inv-status-list', this);
        var $mppPrice = $('.js-product-price', this);
        var skuBaseId = $statusList.data('sku-base-id') || $(this).data('sku-base-id');
        var skuDataL2 = prodcat.data.getSku(skuBaseId);

        if (!skuDataL2) {
          return;
        }

        // Hide price in MPP if the product is promotional(loyalty)
        if (
          skuDataL2.INVENTORY_STATUS === 6 &&
          Drupal.settings.common &&
          Drupal.settings.common.hide_price_mpp_loyalty
        ) {
          $mppPrice.hide().addClass('hidden');
        }
      });

      // Filter event:
      //       $grids.on('productGrid.filter', function(event, prodsToShow, sort) {
      //         var $items = $('.js-product-grid-item', this);
      //         var $filteredItems = $items.filter(function() {
      //           return _.contains(prodsToShow, $(this).data('product-id'));
      //         });
      //         var $grid = $(this);
      //         var $container = $items.first().parent();
      //
      //         // First, undo any previous sorting we may have done:
      //         _resetFilterSort($grid);
      //
      //         // (Optionally) Sort:
      //         if (sort) {
      //           // Put a placeholder before the items we're going to sort so we can
      //           // un-sort them later (_resetFilterSort).
      //           $filteredItems.before(sortPlaceholder);
      //           // Reverse the array because we're prepending. Appending also works,
      //           // but this way we can target :first-child in css to get the primary
      //           // result in regimens.
      //           _.each(_.clone(prodsToShow).reverse(), function(id) {
      //             var $item = $filteredItems.filter('[data-product-id="' + id + '"]');
      //             $item.prependTo($container);
      //           });
      //         }
      //
      //         // Filter:
      //         $items.addClass('hidden');
      //         $filteredItems.removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });

      // Reset filter event:
      //       $grids.on('productGrid.showAll', function() {
      //         _resetFilterSort($(this));
      //         $('.js-product-grid-item', this).removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });
    }
  };

  //   function _resetFilterSort($grid) {
  //     var $items = $('.js-product-grid-item', $grid);
  //     $('.js-product-grid-sort-placeholder', $grid).each(function() {
  //       var id = $(this).data('placeholder-product-id');
  //       var $item = $items.filter('[data-product-id="' + id + '"]');
  //       $(this).after($item).remove();
  //     });
  //   }
})(jQuery);
